import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";

import {useTranslation} from "react-i18next";

import {Button, ButtonToolbar, FlexboxGrid, Form, InputPicker, Modal} from "rsuite";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Table from "../../components/Table/Table";
import AddToComare from "../../components/AddToComare/AddToComare";
import Subscription from "../../components/Subscription/Subscription";
import ProductCountTable from "../../components/ProductCountTable/ProductCountTable";

import ProductSlider from "./ProductSlider/ProductSlider";
import ProductSliderUsuallyBoughtTogether
    from "./ProductSliderUsuallyBoughtTogether/ProductSliderUsuallyBoughtTogether";

import productService from "../../services/productService";
import SingleProductModel from "../../dto/products/SingleProductModel";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";

import NoImage from '../../assets/img/no_img_b.jpg';

import "./ProductDescription.sass"
import {useAppSelector} from "../../hooks";


const ProductDescription: React.FC = () => {

    const { t } = useTranslation();

    const wsRate = useAppSelector<number>((state) => state.wsRate.value);
    let currentLang: string = useAppSelector((state) => state.currentLang.value);

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(true)

    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    let {productId} = useParams();

    const id = productId == undefined ? 0 : +productId

    const [productInfo, setProductInfo] = useState<SingleProductModel>()
    const [toggleProperties, setToggleProperties] = useState(false)

    useEffect(() => {
        productService.getProduct(id).then(data => {
            setProductInfo(data)
            setHtml(data.description)
            setToggleProperties(Object.keys(data.properties).length > 8 ? true : false)
        })
        handleClickScroll('inner-block')
    }, [productId, currentLang])


    const [imagesForSlider, setImagesForSlider] = useState<string[]>([])


    useEffect(() => {

        if(productInfo != undefined){

            setImagesForSlider(productInfo?.images)

            Object.keys(productInfo.properties).map(item => {
                if (item == t('video').toLowerCase()){

                    setImagesForSlider(prevState => ([...prevState, productInfo.properties[item]]))
                }

            })
        }
    }, [productInfo])



    const handleClickScroll = (link: string) => {
        const element = document.getElementById(link);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    const [numberProperties, setNumberProperties] = useState(8)

    const showAllProperties = () => {
        setToggleProperties(toggleProperties => !toggleProperties)
        productInfo ? setNumberProperties(Object.keys(productInfo.properties).length) : setNumberProperties(5)
    }

    const [showDescription, setShowDescription] = useState(false)
    const toggleDescription = () => {
        setShowDescription(showDescription => !showDescription)
    }


    function getSlicedDataFromMap(dataSet: Map<string, string>, startIndex: number, endIndex: number) {
        return Array
            .from(dataSet.keys()).slice(startIndex, endIndex)
            .reduce((m, k) => m.set(k, dataSet.get(k)), new Map)
    }

    const breadcrumbs = [
        {
            'link': '/',
            'linkText': t('main')
        },
        {
            'link': `/category/${productInfo?.categoryId}`,
            'linkText': productInfo?.categoryName
        },
        {
            'link': null,
            'linkText': productInfo?.name
        }]

    const [html, setHtml] = useState<string>("")
    useEffect(() => {
        if (productInfo) {
            setHtml(productInfo.description)
        }
    }, [html])


    const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
    const [openedSlide, setOpenedSlide] = useState(0)

    const [open, setOpen] = useState(false);
    const handleOpen = () =>{
        setOpen(true)
        setOpenedSlide(currentSlideNumber)
    }
    const handleClose = () => {
        setOpen(false)
        setCurrentSlideNumber(openedSlide)
    }

    const productWrapperRef = useRef<any>(null)
    useEffect(()=> {
        productWrapperRef.current.scrollIntoView()
    },[productId])

    return (
        <div id="product-page-start" ref={productWrapperRef}>

            <Modal open={open} onClose={handleClose} className="modal-slider">
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <ProductSlider
                        openModalSlider={handleOpen}
                        images={imagesForSlider}
                        setInitialSlide={setCurrentSlideNumber}
                        initialSlide={currentSlideNumber}
                    ></ProductSlider>
                </Modal.Body>
            </Modal>

            <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
            {productInfo != undefined ? <div>
                <div className="list_menu_product-wrapper">
                {productInfo != undefined ? <ul>
                    <li><button onClick={() => handleClickScroll('all')}>{t('singleProduct.allDescription')}</button></li>
                    {productInfo.properties != undefined ?  <li><button onClick={() => handleClickScroll('properties')}>{t('singleProduct.properties')}</button></li> : null }
                    {productInfo.usuallyBoughtTogether.length != 0 ?  <li><button onClick={() => handleClickScroll('usuallyBoughtTogether')}>{t('singleProduct.usuallyBoughtTogether')}</button></li> : null }
                    {productInfo.description != undefined ? <li><button onClick={() => handleClickScroll('description')}>{t('singleProduct.description')}</button></li> : null }
                    {productInfo.similarProducts.length != 0 ? <li><button onClick={() => handleClickScroll('similarProducts')}>{t('singleProduct.similarProducts')}</button></li> : null }
                </ul> : null }
            </div>

            <div className="product-wrapper" id="all">
                <div className="slider-wrapper">

                    {imagesForSlider && imagesForSlider.length != 0 ?
                        <ProductSlider
                            openModalSlider={handleOpen}
                            images={imagesForSlider}
                            setInitialSlide={setCurrentSlideNumber}
                            initialSlide={currentSlideNumber}
                        ></ProductSlider> :
                        <img src={NoImage} alt=""/>}

                </div>

                <div className="product_info-wrapper">
                    <h1>{productInfo?.name}</h1>
                    <Subscription disableNewProducts={true} />

                    {(productInfo != undefined) ? <div className="show-grid">
                        <FlexboxGrid className={"product_info"}>
                            {productInfo.vendorCode != undefined ? <FlexboxGrid.Item className={"vendorCode-wrapper"}>
                                <div className={"title"}>{t('productsTableHeader.vendorCode')}</div>
                                <div className={"value"}>{productInfo.vendorCode}</div>
                            </FlexboxGrid.Item> : null}

                            {productInfo.lastPurchaseDate != undefined ? <FlexboxGrid.Item>
                                <div className="title">{t('productsTableHeader.lastPurchase')}</div>
                                <div className={"value"}>{productInfo.lastPurchaseDate} <span>{productInfo.lastPurchaseCount ? productInfo.lastPurchaseCount : null}</span></div>
                            </FlexboxGrid.Item> : null}

                            {productInfo.recommendedRetailPrice != undefined ? <FlexboxGrid.Item>
                                <div className={"title"}>{t('productsTableHeader.rrc')}</div>
                                <div className={"value"}>{Math.round(productInfo.recommendedRetailPrice * wsRate)} {t('uah')}</div>
                            </FlexboxGrid.Item> : null}

                            {productInfo.price != undefined ? <FlexboxGrid.Item>
                                <div className={"title"}>{t('productsTableHeader.price')}</div>
                                <div className={"value"}><strong>{Number(productInfo.price).toFixed(2)} $</strong></div>
                            </FlexboxGrid.Item> : null}

                            <FlexboxGrid.Item>
                                <div className={"title"}>{t('productsTableHeader.left')}</div>
                                <div className={"value"}>{productInfo.available > 20  ? '>20' : productInfo.available}</div>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item className={"toCart-wrapper"}>
                                <div className={"title"}>{t('productsTableHeader.toCart')}</div>
                                <div className={"value"}><ProductCountTable buttonName={t('add')} appointment={"cart"} product={productInfo}></ProductCountTable></div>
                            </FlexboxGrid.Item>

                            {/*<FlexboxGrid.Item className={"toReserve-wrapper"}>
                                <div className={"title"}>{t('productsTableHeader.toReserve')}</div>
                                <div className={"value"}><ProductCountTable buttonName={t('add')} appointment={"reserve"} product={productInfo}></ProductCountTable></div>
                            </FlexboxGrid.Item>*/}

                            <FlexboxGrid.Item>
                                <div className={"title"}>{t('productsTableHeader.totalPrice')}</div>
                                <div className={"value"}><strong>{Number(productInfo.price).toFixed(2)} $</strong></div>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item>
                                <div className={"title"}>{t('productsTableHeader.income')}</div>
                                <div className={"value"}><strong>{Number(productInfo.recommendedRetailPrice - productInfo.price).toFixed(2)} $</strong></div>
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item className={"compare-wrapper"}>
                                <div className={"title"}></div>
                                <div className={"value"}><AddToComare chosenProduct={productInfo}></AddToComare></div>
                            </FlexboxGrid.Item>

                        </FlexboxGrid>
                    </div> : null}


                    <ul className={"property-list"}>
                        {productInfo != undefined ? Object.keys(productInfo.properties).slice(0,4).map((item, index) => {
                            return <li key={index}><span className={"property-name"}>{item}:</span> {productInfo.properties[item]}</li>
                        }) : null}
                    </ul>

                    {productInfo?.description != undefined ?
                        <div className="description">
                            <div dangerouslySetInnerHTML={{__html: html}}></div>
                        </div>
                        : null}

                </div>
            </div>
            {productInfo != undefined ? <div className="properties-wrapper" id="properties">

                {productInfo.properties != undefined ? <div className={"productInfo-properties-wrapper"}>
                    <h2>{t('singleProduct.properties')}</h2>
                    <div className={"product-name"}>{productInfo?.name}</div>
                    {Object.keys(productInfo.properties).length != 0 ? <ul
                        className={toggleProperties ? "productInfo-properties-list" : "productInfo-properties-list show-all"}>
                        {Object.keys(productInfo.properties).slice(0, numberProperties).map((item, index) => {
                            return (<li key={index}><span className={"property-name"}>{item}:</span> {productInfo.properties[item]}</li>)
                        })} </ul> : null}

                </div> : null}
                {Object.keys(productInfo.properties).length > 8 ? <button className="btn-usual" onClick={showAllProperties}>{toggleProperties ? t('singleProduct.allProperties') : t('singleProduct.hideProperties')}</button> : null}

                <div className={"productInfo-description-wrapper"} id="description">

                    {productInfo.description ? <div>
                        <h2>{t('singleProduct.description')}</h2>
                        <div className={"product-name"}>{productInfo?.name}</div>
                        <div className={showDescription ? "productInfo-description show-all" : "productInfo-description"}>
                            <div dangerouslySetInnerHTML={{__html: html}}></div>
                        </div>
                        <button className="btn-usual" onClick={toggleDescription}>{showDescription ? t('singleProduct.hide') : t('singleProduct.more')}</button>

                    </div> : null}

                    {productInfo.usuallyBoughtTogether && productInfo.usuallyBoughtTogether.length != 0 ? <div className={"productInfo-usuallyBoughtTogether-wrapper"} id="usuallyBoughtTogether">

                        <h2>{t('singleProduct.usuallyBoughtTogether')}</h2>

                        {productInfo.usuallyBoughtTogether && productInfo.usuallyBoughtTogether.length != 0 ? <div className={"productInfo-usuallyBoughtTogether-list"}>
                            <ProductSliderUsuallyBoughtTogether productInfo={productInfo.usuallyBoughtTogether}></ProductSliderUsuallyBoughtTogether>
                        </div> : null}

                    </div> : null}

                    {productInfo.similarProducts && productInfo.similarProducts.length != 0 ? <div className="productInfo-similarProducts-wrapper" id="similarProducts">
                        <h2>{t('singleProduct.similarProducts')}</h2>

                        {productInfo.similarProducts.length != 0 ? <div className={"productInfo-similarProducts-list"}>
                            <Table data={productInfo.similarProducts.map((item) => ProductEntity.CreateProductEntityShort(item))} openModal={false} handleClose={() => {}} pagination={false} />
                        </div> : null}

                    </div> : null}



                </div>


            </div> : null}

                </div>: null}

        </div>
    )
}
export default ProductDescription;