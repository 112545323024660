import Product from "./Product";
import ShortProductModel from "../../dto/products/ShortProductModel";
import {ComparisonProduct} from "../ComparisonCategory"
import GetOrderItemModel from "../../dto/order/orderItems/GetOrderItemModel";

export class ProductEntity implements Product{
    id: number = 0;
    vendorCode: string = '';
    photoUrl: string = '';
    name: string = '';
    lastPurchaseDate: string = '';
    available: number = 0;
    recommendedRetailPrice: number = 0;
    price: number = 0;
    lastPurchaseCount: number = 0;
    toReserve: string = '';
    toCart: string = '';
    totalPrice: number = 0;
    income: number = 0;
    compare: string = '';
    categoryId: number = 0;
    count: number = 0;

    static CreateProductEntityShort(item: ShortProductModel) {
        const entity = new ProductEntity()
        entity.name = item.name
        entity.id = item.id
        entity.vendorCode = item.vendorCode
        entity.photoUrl = item.photoUrl
        entity.lastPurchaseDate = item.lastPurchaseDate
        entity.available = item.available
        entity.lastPurchaseCount = item.lastPurchaseCount
        entity.recommendedRetailPrice = item.recommendedRetailPrice
        entity.price = item.price
        entity.toReserve = "В резерв"
        entity.toCart = "В корзину"
        entity.totalPrice = item.price
        entity.income = item.recommendedRetailPrice - item.price
        entity.compare = ""
        entity.categoryId = item.categoryId

        return entity
    }

    static CreateProductEntityComparison(item: ComparisonProduct) {
        let entity = new ProductEntity()
        entity.name = item.name
        entity.id = item.id
        entity.vendorCode = item.vendorCode
        entity.photoUrl = item.photoUrl
        entity.available = item.available
        entity.recommendedRetailPrice = item.recommendedRetailPrice
        entity.price = item.price
        entity.lastPurchaseCount = item.lastPurchaseCount
        entity.toReserve = "В резерв"
        entity.toCart = "В корзину"
        entity.totalPrice = item.price
        entity.income = item.recommendedRetailPrice - item.price
        entity.compare = ""
        entity.categoryId = item.categoryId

        return entity
    }

    static CreateProductEntityOrder(item: GetOrderItemModel) {
        let entity = new ProductEntity()
        entity.id = item.productId
        entity.vendorCode = item.vendorCode
        entity.photoUrl = item.photoUrl
        entity.name = item.productName
        entity.lastPurchaseDate = item.lastBoughtOn
        entity.price = item.price
        entity.available = item.left
        entity.count = item.count
        entity.recommendedRetailPrice = item.recommendedRetailPrice
        //entity.lastBoughtCount = item.lastBoughtCount
        return entity
    }

}