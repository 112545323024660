import React from "react";
import {SubscriptionType} from "../../../../enums/SubscriptionType";

const Type:React.FC<{type: SubscriptionType}> = ({type}) => {
    return (
        <span>
            {type == SubscriptionType.NewProducts ? ' Уведомления о новых товарах' : null}
            {type == SubscriptionType.Availability ? ' Уведомления о появлении товаров в наличии' : null}
            {type == SubscriptionType.Both ? ' Уведомления о новых товарах, уведомления о появлении товаров в наличии' : null}
        </span>
    )
}
export default Type