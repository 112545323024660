import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {MaskedInput, Form} from "rsuite";

import './PhoneInput.sass'

const PhoneInput: React.FC<{valueFunction: Function, valuePhone?: string, label?: boolean, showError?: boolean, labelText?: string | undefined}> = (
    {valueFunction, valuePhone, label, showError, labelText}) => {
    const { t } = useTranslation();

    const options = [
        {
            name: 'US phone number',
            mask: ['+',  '3',  '8', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
            placeholder: '+38(___) ___-__-__'
        },
    ]

    const [option, setOption] = React.useState(options[0]);
    const [value, setValue] = useState('');
    const [guide, setGuide] = React.useState(true);
    const [showMask, setShowMask] = React.useState(false);
    const [keepCharPositions, setKeepCharPositions] = React.useState(true);
    const [placeholderChar, setPlaceholderChar] = React.useState('_');


    useEffect(() => {
        if (valuePhone){
            setValue(valuePhone)
        }

    }, [])

    /*useEffect(() => {
        console.log('value',value)
        valueFunction(value)
    }, [value])*/

    const phoneChange = (phone: string) => {
        setValue(phone)
        valueFunction(phone)
    }



    return (
        <div className="enter_phone-wrapper">
            {label ? labelText ? labelText : t('login.phone') : null }
            <MaskedInput
                value={value}
                mask={option.mask}
                guide={guide}
                showMask={showMask}
                keepCharPositions={keepCharPositions}
                placeholder={option.placeholder}
                placeholderChar={placeholderChar}
                //onChange={setPhoneValue}
                onChange={phoneChange}
            />
            <Form.ErrorMessage show={showError} placement={'bottomStart'}>
                {t('form.requiredFieldMsg')}
            </Form.ErrorMessage>

        </div>
    )
}
export default PhoneInput